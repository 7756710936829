// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-wrapper {
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.layout-wrapper {
  display: grid;
  grid-template-columns: 270px 1fr;
}
.layout-wrapper.login {
  grid-template-columns: 1fr;
}
.layout-wrapper.login .main-wrapper {
  background-color: #ffffff;
}

.main-wrapper {
  background-color: #f9f9f9;
}

@media screen and (max-width: 699px) {
  .layout-wrapper {
    grid-template-columns: 1fr;
  }
  .layout-wrapper .sidebar-wrapper {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./projects/admin/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,+CAAA;EACA,aAAA;AACF;;AAEA;EACE,aAAA;EACA,gCAAA;AACF;AACE;EACE,0BAAA;AACJ;AACI;EACE,yBAAA;AACN;;AAIA;EACE,yBAAA;AADF;;AAIA;EACE;IACE,0BAAA;EADF;EAGE;IACE,aAAA;EADJ;AACF","sourcesContent":[".sidebar-wrapper {\n  background-color: #fff;\n  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);\n  height: 100vh;\n}\n\n.layout-wrapper {\n  display: grid;\n  grid-template-columns: 270px 1fr;\n\n  &.login {\n    grid-template-columns: 1fr;\n\n    .main-wrapper {\n      background-color: #ffffff;\n    }\n  }\n}\n\n.main-wrapper {\n  background-color: #f9f9f9;\n}\n\n@media screen and (max-width: 699px) {\n  .layout-wrapper {\n    grid-template-columns: 1fr;\n\n    .sidebar-wrapper {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

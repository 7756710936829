export const environment = {
  domainExt: window.location.host.split('.').pop(),
  apiUrl: 'https://devapi.maptv.dallasmarketcenter.app/',
  // apiUrl: 'http://12.12.0.37:8080/',
  mappedinApiGatewayUrl: 'https://api-gateway.mappedin.com/',
  identityTokenGatewayUrl: 'https://devids.unityedge.com/',
  unityApiGatewayUrl: 'https://devapi.unityedge.com/',
  bucketUrl: 'https://dmc-mappedin-dev-asset.s3.amazonaws.com/',
  production: false,
  showError: false
};
